import $ from 'jquery';

class Breadcrumb {
    static name() {
        return 'Breadcrumb';
    }

    constructor(props, el, $, Utils) {
        this.objects = {
            $el: $(el),
            $breadcrumbList: $(el).find('#breadcrumb-list'),
            $fullBreadcrumb: [],
            isExpanded: false
        };

        this.utils = new Utils();

    }

    init() {
        if (this.objects.$breadcrumbList.length === 0) {
            console.error('Breadcrumb list element not found.');
            return;
        }

        this.updateBreadcrumb();
        
        $(window).on('resize', () => this.updateBreadcrumb());
        $(window).on('load', () => this.updateBreadcrumb());
        $(document).on('click', (event) => {
            if (window.innerWidth < 768 && !this.objects.$el.is(event.target) && this.objects.$el.has(event.target).length === 0 ) {
                this.collapseBreadcrumb();
            }
        });

        $(window).on('scroll', () => {
            const itemCount = this.objects.$breadcrumbList.children().length;

            if (this.objects.isExpanded && window.innerWidth < 768 && itemCount >= 4) {
                this.collapseBreadcrumb();
            }
        });
    }

    updateBreadcrumb() {
        const items = this.objects.$breadcrumbList.children();
        const screenWidth = window.innerWidth;
        const itemCount = items.length;

        if (this.objects.$fullBreadcrumb.length === 0) {
            this.objects.$fullBreadcrumb = Array.from(items).map(item => $(item).clone()[0]);
        }

        if (screenWidth < 768) {
            if (itemCount >= 4) {
                this.displayCollapsed(items);
            } else {
                this.objects.$el.addClass('collapsed');
                this.objects.$el.removeClass('expanded');
            }
        } else {
            this.displayFull();
        }
    }

    displayCollapsed(items) {
        this.objects.$el.addClass('collapsed');
        this.objects.$el.removeClass('expanded');
        const firstItem = $(items[0]).clone();
        const secondLastItem = $(items[items.length - 2]).clone();
        const lastItem = $(items[items.length - 1]).clone();
        this.objects.$breadcrumbList.empty();

        this.objects.$breadcrumbList.append(firstItem);
        const ellipsis = $('<li><span class="ellipsis" style="cursor: pointer;" aria-hidden="true"><svg class="ellipsis-light"><use xlink:href="#icon-icon-ellipsis-light"></use></svg><svg class="ellipsis-dark"><use xlink:href="#icon-icon-ellipsis-dark"></use></svg></span></li>');
        ellipsis.on('click', (event) => {
            event.stopPropagation();
            this.displayFull();
        });
        this.objects.$breadcrumbList.append(ellipsis);
        this.objects.$breadcrumbList.append(secondLastItem);
        this.objects.$breadcrumbList.append(lastItem);

        lastItem.addClass('last-ellipsis');
        
        this.objects.isExpanded = false;
    }

    displayFull() {
        const items = this.objects.$breadcrumbList.children();
        const screenWidth = window.innerWidth;
        const itemCount = items.length;

        this.objects.$el.removeClass('collapsed');
        this.objects.$breadcrumbList.empty();
        this.objects.$fullBreadcrumb.forEach(item => this.objects.$breadcrumbList.append($(item).clone()));
        
        const lastItem = this.objects.$breadcrumbList.children().last();
        lastItem.addClass('last-ellipsis');
        
        this.objects.isExpanded = true;
        if (screenWidth < 768 && itemCount >= 4) {
                this.objects.$el.addClass('expanded');
        }
    }
    
    collapseBreadcrumb() {
        if (this.objects.isExpanded) {
            this.displayCollapsed(this.objects.$fullBreadcrumb);
        }
    }
}

export default Breadcrumb;