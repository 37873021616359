import 'bootstrap';
import $ from 'jquery';
import 'promise-polyfill/src/polyfill';
import 'intersection-observer';
import Main from './Main';

// Utilities, Polyfills, Etc...
import Utils from './utilities/Utils';
import './polyfills/CustomEvent';
import './polyfills/find';

// Components
import Accordion from './components/Accordion';
import Alert from './components/Alert';
import AnchorDropdown from './components/AnchorDropdown';
import AutoComplete from './components/AutoComplete';
import Breadcrumb from './components/Breadcrumb';
import Careers from './components/Careers';
import Carousel from './components/Carousel';
import ChartCore from './components/ChartCore';
import CustomForm from './components/CustomForm';
import DatasheetFinder from './components/DatasheetFinder';
import EvaluationBoard from './components/EvaluationBoard';
import FAQ from './components/FAQ';
import FAQSearch from './components/FAQSearch';
import FilterList from './components/FilterList';
import FilterDropdown from './components/FilterDropdown';
import GeoLocation from './components/GeoLocation';
import HeroSlider from './components/HeroSlider';
import HeroInterior from './components/HeroInterior';
import HeroBanner from './components/HeroBanner';
import HeroSliderEdit from './components/HeroSliderEdit';
import LanguageSwitcher from './components/LanguageSwitcher';
import LocationSwitcher from './components/LocationSwitcher';
import PressArticle from './components/PressArticle';
import ReactSample from './components/ReactSample';
import Header from './components/Header';
import PowerSystemDesigner from './components/PowerSystemDesigner';
import PressRoom from './components/PressRoom';
import SearchResults from './components/SearchResults';
import PreFooterForm from './components/PreFooterForm';
import ProductOptionSelection from './components/ProductOptionSelection';
import VicorResourceLibrary from './components/VicorResourceLibrary';
import ValidateAndExecuteChart from './components/ValidateAndExecuteChart';

import '../css/main.scss';

let modules = [
	Accordion,
	Alert,
	AnchorDropdown,
	AutoComplete,
	Breadcrumb,
	Careers,
	Carousel,
	ChartCore,
	CustomForm,
	DatasheetFinder,
	EvaluationBoard,
	FAQ,
	FAQSearch,
	FilterList,
	FilterDropdown,
	GeoLocation,
	HeroSlider,
	HeroInterior,
	HeroSliderEdit,
	HeroBanner,
	LanguageSwitcher,
	LocationSwitcher,
	ReactSample,
	Header,
	PowerSystemDesigner,
	PreFooterForm,
	PressArticle,
	PressRoom,
	ProductOptionSelection,
	VicorResourceLibrary,
	SearchResults,
	ValidateAndExecuteChart
];

console.info('/ / / / VICOR/ / / /');

//endsWith(..) is not supported in IE, defining it here
if (!String.prototype.endsWith) {
	String.prototype.endsWith = function(searchString, position) {
		let subjectString = this.toString();
		if (typeof position !== 'number' || !isFinite(position)
			|| Math.floor(position) !== position || position > subjectString.length) {
			position = subjectString.length;
		}
		position -= searchString.length;
		let lastIndex = subjectString.indexOf(searchString, position);
		return lastIndex !== -1 && lastIndex === position;
	};
}

let core = new Main($, modules, Utils);
core.init();
